<template>
    <div class="min-vh-100 p-3">
        <form-personal-data></form-personal-data>
</div>
</template>

<script>
import FormPersonalData from "@/components/manager/personalData/FormPersonalData";
export default {
name: "PersonalData",
        components: {FormPersonalData},
        props: {},
        data() {
            return {
            }
        },
        mounted() {},
        methods: {},
        computed: {},
        filters: {},
        watch: {}
}
</script>

<style scoped>

</style>
